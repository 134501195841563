import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #009 - Jonathan Elliott, Battery 245"}
    blogUrl={"https://joincrafted.com/009-jonathan-elliott-battery-245"}
    description={
      "In the works of opening his first brewery, Jonathan Elliott of Battery 245 (Warrenton, OR, USA) shares about the ups and downs of this endeavor."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/battery245/battery245.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=In%20the%20works%20of%20opening%20his%20first%20brewery,%20Jonathan%20Elliott%20of%20Battery%20245%20(Warrenton,%20OR,%20USA)%20shares%20about%20the%20ups%20and%20downs%20of%20this%20endeavor.%0A%0Ahttps%3A//joincrafted.com/009-jonathan-elliott-battery-245"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/009-jonathan-elliott-battery-245"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>December 7, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;009 🍻 - Jonathan Elliott, Battery 245</h1>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>Here at Crafted we've been interviewing brewers who've already launched their own breweries. But what about the stories of those who are on the other side - still pressing forward towards this dream?</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Today we hear from someone in the process of starting their brewery.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '8px 0 32px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Jonathan Elliott</strong> has been homebrewing for a decade and is now making the jump to professional brewing. He just signed a lease for <span style={{color: '#6821AB', fontWeight: 700}}><strong>Battery 245 Brewing</strong></span> in Warrenton, OR, USA and hopes to open the doors in the coming year.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>When we read the journey of a brewer, we get to journey with them. I hope that as you learn about Jonathan's journey you can draw some insight and inspiration for your own.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>In this edition, you'll see:
                                                </p>
                                                <ul>
                                                  <li>How one double IPA opened the doors of opportunity</li>
                                                  <li>The importance of your local bottle shop</li>
                                                  <li>Beer celebrates the occasions that matter in life</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              </div>
                                              <EmailFormInPage />
                                              </td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>How'd you get into homebrewing and how long have you been doing it?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I got introduced to homebrewing in 2009, when I was a manager for company called McMenamins (if you're not familiar with them, check them out!) and my roommate that I lived with was into homebrewing. She ended up being in brewing mags and has brewed for a few different breweries.</p>
                                                <p style={{paddingBottom: '16px'}}>Early 2010, I moved back home to Warrenton, Oregon, and bought a kit and was determined to figure it out! So I've been brewing for 10 years or so.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>You recently signed a lease, which takes you one more step towards opening your brewery. Why did you decide to start your own brewery? What has been the best part of the process so far? The worst?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>My partner and I have been in the restaurant business for almost 2 decades. When I was 23 (40 now) he was 18, and he cooked at a sports bar where I bartended. We really enjoy the service industry! We've wanted to open one for at least 10 years, but were not quite sure how to do it. Two years ago, Eric, my partner, was tending bar, and one of the owners of a local brewery was there. They got to talking, one thing lead to another, and we were able to make a 20bbl batch of one of my double IPAs. That right there opened up opportunities.</p>
                                                <p style={{paddingBottom: '16px'}}>I was approached by a guy that really liked the beer, and he said <em>this is what you should be doing - let's start a brewery!</em></p>
                                                <p style={{paddingBottom: '16px'}}>After months of putting together a business plan, and gathering 4 other partners, there was a disagreement between partners that lead to a falling-out and the loss of the location.</p>
                                                <p style={{paddingBottom: '16px'}}>So we disbanded, but Eric and I were still looking!</p>
                                                <p style={{paddingBottom: '16px'}}>That Winter, another building presented another opportunity, where we chatted with the owner for over 3 months to come to an agreement. In a nut shell, we started this adventure because it's what we enjoy doing, and we're passionate about beer!</p>
                                                <p style={{paddingBottom: '16px'}}>Best part is knowing that we're working towards what we love to do and can't wait to open! The support we recieve from the community is great! The stress is the worst part. If you try to focus on everything all at once it will drive you insane. Some things will be out of your control and you'll be at a stand still, and it will seem like you've done nothing for weeks, and that can produce some negative thoughts and energy. Just have to focus on what you can and stay positive.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} bgcolor>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/battery245/battery245.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} bgcolor><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Jonathan and Eric in front of the future Battery 245 Brewing
                                              </div>
                                            </td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} bgcolor>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/battery245/battery245_logo.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Can you tell us about a beer that you've been homebrewing, which you're excited to start brewing commercially? Why is this beer special to you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I literally brew everything! I live less than a mile from a bottle shop, where I drink some of the best beer, not only just from Oregon, Washington and California, but from around the nation. So I do lots of research and development!</p>
                                                <p style={{paddingBottom: '16px'}}>Specialty styles is what I enjoy the most! Being able to experiment. I've made a peach belini hazy ipa, a french 75 ipa, cinnamon toast crunch blueberry sour. Love experimenting with new hops and grains. I bring all my beers to my local bottle shop for tastings, if you can win these guys over, you have arrived! They're the toughest crowd!</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What does success look like for you and your brewing in 5-10 years from now?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Right now I'm building my own nano HERMS system. It will be a 1/2 bbl system, where we will double batch 4 to 5 times a week to hit our goals. I also have a smaller electric 1/6th bbl system that we will use as well. Both these systems will allow me to turn beer over, from grain to glass in 10 days or less. So the exciting part is there will always be something new on tap!</p>
                                                <p style={{paddingBottom: '16px'}}>The building we signed a lease on, is 4000sqft. There's 3 commercial spaces in the building. We'll use 2 of them, and save the 3rd space for a production system when we're ready to make the jump! We're quite lucky the building owner is a great guy and shares our vision. He's also recieved a facade grant to help fix up the outside of the building! That's really cool!</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>If you had to describe the craft beer community in one word, what would it be? Why?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>This is a tricky question, because it's probably not the same for everyone! It's a bithday, a holiday, friendship, catching up with someone, a season, making a certain style just for someone. I guess in one word, its life. From one beer to the next!</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} bgcolor>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/battery245/jonathan_and_eric_battery245.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} bgcolor><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Jonathan (left) and Eric (right) enjoying the double IPA at the release party that opened the doors of opportunity
                                              </div>
                                              <EmailFormInPage />
                                            </td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>Jonathan is pressing forward on the long, sometimes rocky, road of launching a brewery.</p>
                                                <p style={{paddingBottom: '8px'}}>What's cool is that he's not <em>just</em> trying to turn his hobby into a career. <strong>He's trying to share his passion with others.</strong> He's trying to open the doors of a place where life's worth celebrating.</p>
                                                <p style={{paddingBottom: '8px'}}>When there's a birthday, a holiday, a friendship to celebrate - Battery 245 can be the place where memories are made.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>That's really why we make stuff. That's what beer is for.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} bgcolor><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                Thank you <strong>Jonathan</strong> sharing your journey with us. All the best to you as launch Battery 245! We'll be sure to follow-up to see how things are going with the brewery.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} bgcolor> <tbody> <tr> <td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Thanks again to you each for reading. You rock 🤘</p>
                                                <p style={{paddingBottom: '16px'}}>We've been focusing on homebrewers that have opened their own brewing companies, <strong>but I've got some new ideas in the works</strong>. In our next edition we'll talk beer with a micro-biologist. And after that I've got some cool content planned which deals with beer styles and their histories.</p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px'}}>
                                                <div style={{padding: '8px'}}>
                                                  This week's story was made possible by <strong>Yakima Valley Hops</strong>. <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" style={{textDecoration: 'underline', color: '#6821AB'}} target="_blank" rel="noreferrer">They've got a new hop aromas kit which teaches you about the characteristics of different hops. It's a cool tool to have in your recipe-planning arsenal.</a>
                                                </div>
                                              </div></td>
                                          </tr>
                                        </tbody>
                                      </table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '-8px'}} bgcolor>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="/assets/images/yakima_valley_hops_kit_1000x500.jpeg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} alt="Yakima Valley Hops Kit" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table></td></tr></tbody></table></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
